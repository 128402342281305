/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, useEffect, Fragment } from "react";
import utils from "../../../utils";
import { SiteLink, Button, usePost, ErrorMessage } from "../../../components";
import { tileStyle, portalStyle } from "./productTileStyleV2";
import { fb, validators, useForm } from "../../../form";
//import { Attributes } from "./attributes/attributes1";
import env from "../../../env";
import { useStore } from "../../../store";
import { showAddCartMsg } from "../../siteUtils";
import { createPortal } from "react-dom";
import { MdClose } from "react-icons/md";
import { getEnquiryFormModel } from "./enquiryFormModel";
import { useAuth } from "auth";
//import style from "components/photoGallery/style";

function Portal({ children }) {
  const [el] = useState(document.createElement("div"));
  useEffect(() => {
    let mount = document.getElementById("portal-root");
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el]);
  return createPortal(children, el);
}

export function ProductTile({ product, openMsgPopUp }) {
  const auth = useAuth();
  const memberInfo = auth.authData.memberInfo;

  const s = tileStyle;
  const imageUrl = product.thumbnailUrl
    ? utils.site.resourcePath(product.thumbnailUrl)
    : "/assets/logos/logo2.png";
  //const imageUrl = 'https://dummyimage.com/600x400/000/fff';
  const productUrl = product.productUrl
    ? product.productUrl
    : memberInfo.memberType === 99
    ? `/member/admin/product/${product.productSlug}`
    : `/member/product/${product.productSlug}`;

  const model = getFormModel(product, { usePlaceholder: true });
  //const form = useForm(model, { usePlaceholder: true, onControlChanged: onControlChanged });
  const form = useForm(model, {
    usePlaceholder: true,
    onControlChanged: onControlChanged,
  });

  //const render = (name, parent = null) => form.renderControl(name, parent);
  //const attributes = form.findControl("variantOptions");

  const post = usePost();
  const { dispatch } = useStore();
  const [openPopup, setOpenPopup] = useState(false);

  // const [productPrice, setProductPrice] = useState(() => {
  //   let _price = product.priceIncTax;
  //   for (let i in product.tiredPricings) {
  //     let _rangeMax = product.tiredPricings[i].rangeMax
  //       ? product.tiredPricings[i].rangeMax
  //       : 10000000;
  //     if (product.tiredPricings[i].rangeMin <= 1 && _rangeMax >= 1) {
  //       _price = product.tiredPricings[i].pricePerUnit;
  //       break;
  //     }
  //   }
  //   return _price;
  // });

  // eslint-disable-next-line
  const [productPrice, setProductPrice] = useState(product.priceIncTax);
  const enquiryFormModel = getEnquiryFormModel({});
  const enquiryForm = useForm(enquiryFormModel, { usePlaceholder: true });
  const enquiryFormRender = (name) => enquiryForm.renderControl(name, null);

  //function onControlChanged(e, control) {
  //   if (!(control.type === 'select' && control.parent)){
  //     return;
  //   }
  //   const newModel = getFormModel(product, form.getValue())
  //   form.reset(newModel)
  //}

  function onControlChanged(e, control) {
    if (control && control.name === "quantity") {
      let _price = product.priceIncTax;
      for (let i in product.tiredPricings) {
        let _rangeMax = product.tiredPricings[i].rangeMax
          ? product.tiredPricings[i].rangeMax
          : 10000000;
        if (
          product.tiredPricings[i].rangeMin <= control.value &&
          _rangeMax >= control.value
        ) {
          _price = product.tiredPricings[i].pricePerUnit;
          break;
        }
      }
      setProductPrice(_price);
    }
  }

  // function resetFormChange() {
  //   const newModel = getFormModel(product, form.getValue());
  //   form.reset(newModel);
  // }

  // function openEnquiry() {
  //   setOpenPopup(true);
  //   document.body.style.overflow = "hidden";
  // }

  function closeEnquiry() {
    setOpenPopup(false);
    document.body.style.overflow = "unset";
  }

  // function addToCart(e) {
  //   form.validateForm(e, () => {
  //     const formVal = form.getValue();
  //     let _variantOptions = [];
  //     for (let i in formVal.variantOptions) {
  //       let _item = {
  //         name: formVal.variantOptions[i].name,
  //         value:
  //           formVal.variantOptions[i][
  //             Object.keys(formVal.variantOptions[i])[1]
  //           ],
  //       };
  //       _variantOptions.push(_item);
  //     }

  //     let _cartId = env.getDevCartId();

  //     const value = {
  //       quantity: formVal.quantity,
  //       variantOptions: _variantOptions,
  //       productId: product.productId,
  //       productPrice: productPrice,
  //       //cartId: _cartId === 'null' || 'undefined' ? null : _cartId
  //       cartId: _cartId === "null" || _cartId === "undefined" ? null : _cartId,
  //     };

  //     post.send(env.apiBase + "/api/cart/addtocartforclubmanger", value);
  //     //post.send(env.apiBase + "/api/cart/addtocartforclubmangertest", value);
  //   });
  // }

  function onSubmit(e) {
    enquiryForm.validateForm(e, () => {
      const value = {
        ...enquiryForm.getValue(),
        productName: product.productName,
        emailReceiver: "",
      };
      //var files = form.getFiles();
      var formData = new FormData();
      formData.append("jsonData", JSON.stringify(value));
      //post.send(env.apiBase + "/api/contact/send", value);
      post.sendFormData(env.apiBase + "/api/cart/sendenquire", formData);
    });
    //console.log('submitting');
  }

  useEffect(() => {
    form.reset(getFormModel(product, { usePlaceholder: true }));
    setProductPrice(product.priceIncTax);

    // eslint-disable-next-line
  }, [product]);

  if (post.done()) {
    env.setDevCartId(post.response.results.cartInfo.cartId);
    const formVal = form.getValue();
    showAddCartMsg(formVal.quantity + " items added to cart");
    setTimeout(() => {
      dispatch({
        type: "SET_STATE",
        payload: {
          cartInfo:
            post.response.results.cartInfo.totalQuantity === 0
              ? 0
              : post.response.results.cartInfo.totalQuantity - 1,
        },
      });
    }, 100);

    // if(post.response.results.minimumQtyInfo && post.response.results.minimumQtyInfo !== ''){
    //   openMsgPopUp(post.response.results.minimumQtyInfo);
    // }

    post.reset();
    // return <Redirect to="/cart" />
  }

  if (post.hasErrors()) {
    //showAddCartMsg("ERROR");
    openMsgPopUp(post.errors[0]);
    post.reset();
  }

  return (
    <Fragment>
      {openPopup && openPopup !== "" && (
        <Portal>
          <div
            css={portalStyle.portal__background}
            className="portal__background"
            onClick={closeEnquiry}
          ></div>
          <div
            css={portalStyle.portal__popupcontainer__centre}
            className="portal__popupcontainer__centre"
          >
            <div
              css={portalStyle.portal__error__msg}
              className="portal__error__msg"
            >
              {" "}
              Enquire Now{" "}
              <div className="close__button">
                <MdClose onClick={closeEnquiry} />
              </div>
            </div>
            <div className="form-row" style={{ marginTop: "1rem" }}>
              <div className="col-md-4 mb-2">{enquiryFormRender("name")}</div>
              <div className="col-md-4 mb-2">{enquiryFormRender("phone")}</div>
              <div className="col-md-4 mb-2">{enquiryFormRender("email")}</div>
            </div>
            <div className="form-row">
              <div className="col-md-12 mb-2">
                {enquiryFormRender("enquiry")}
              </div>
            </div>

            <div className="form-row">
              <div className="col-md-12 mb-2">
                <div className="actions">
                  {/* onClick={onSubmit} status={post.status} */}
                  <Button onClick={onSubmit} status={post.status}>
                    Send Message
                  </Button>
                  <ErrorMessage errors={post.errors} />
                  {post.status === "done" && "Successfully sent!"}
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
      <div css={[s.tile]}>
        <div css={s.tileContainer}>
          <div css={s.prdImage}>
            <SiteLink to={productUrl}>
              <div
                css={s.bg}
                style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}
              ></div>
            </SiteLink>
          </div>

          <div css={s.nameWrap}>
            <div css={s.name}>
              <SiteLink to={productUrl}>{product.productName}</SiteLink>
              {product.shortDesc && product.shortDesc !== "" && (
                <div style={{ marginTop: "3px" }} css={s.shortDes}>
                  {product.shortDesc}
                </div>
              )}
            </div>

            <div css={s.price}>
              {product.productStatus !== 0
                ? product.productStatusText
                : product.priceTextOveriding
                ? product.priceTextOveriding
                : (product.topTire ? "From: " : "") +
                  utils.money.formatMoney(
                    product.topTire
                      ? product.topTire.pricePerUnit
                      : product.priceIncTax
                  )}
              {product.topTire && (
                <div css={s.shortDes} style={{ marginTop: "-8px" }}>
                  {`(${product.topTire.rangeMin} + items)`}
                </div>
              )}
            </div>
          </div>

          <div css={s.button__wrap} className="button__wrap">
            <button className="btn btn-primary" css={s.button__view__product}>
              <SiteLink to={productUrl}>View Product </SiteLink>
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

function getFormModel(product, formValues /* for reset */) {
  const variantAttributes = product.variantAttributes;
  const model = fb.group({
    quantity: [
      formValues.quantity || 1,
      [validators.Required()],
      { label: "Quantity", type: "number" },
    ],
    variantOptions: fb.array([]),
  });
  const variantOptionArray = model.find("variantOptions").controls;
  (variantAttributes || []).forEach((attr) => {
    variantOptionArray.push(
      newVariantAttribute(attr, formValues, product.variants)
    );
  });
  return model;
}

function newVariantAttribute(attr, formValues, variants) {
  const dyName = attr.name.toLowerCase();
  const optionsSelected = formValues.variantOptions || [];
  const currentSelection = utils.array.find(
    optionsSelected,
    (x) => attr.name === x.name
  );
  const filtered = variants.filter((x) =>
    matchVariantOptions(optionsSelected, x.variantOptions, attr.name)
  );
  const availableOptions = getAvailableOptionValues(filtered, attr.name);

  const options = attr.options.map((x) => {
    return {
      ...x,
      ...{ disabled: !availableOptions.some((o) => o === x.value) },
    };
  });

  return fb.group({
    name: [attr.name, [], { type: "hidden" }],
    [dyName]: [
      (currentSelection && currentSelection.value) ||
        attr.value ||
        (options.length > 0 ? options[0].value : ""),
      [validators.Required()],
      {
        //label: attr.name,
        label: null,
        type: "radioList",
        //options : choices
        options: [...options].map((x) => {
          return {
            name:
              (x.displayName || x.value) +
              (x.disabled || false ? " - not available" : ""),
            value: x.value,
            disabled: x.disabled || false,
          };
        }),
      },
    ],
    value: [
      (currentSelection && currentSelection.value) ||
        attr.value ||
        (options.length > 0 ? options[0].value : ""),
      [],
      { type: "hidden" },
    ],
  });
}

function getAvailableOptionValues(variants, name) {
  const values = variants
    .map((x) => {
      const options = x.variantOptions.filter((o) => o.name === name);
      return options && options[0].value;
    })
    .filter((x) => x);

  return Array.from(new Set(values));
}

function matchVariantOptions(
  optionsSelected,
  optionsInVariant,
  currentControlName
) {
  for (let i = 0; i < optionsSelected.length; i++) {
    let optionSelected = optionsSelected[i];
    if (optionSelected.value === "") {
      continue;
    }
    if (optionSelected.name === currentControlName) {
      continue;
    }
    let found = false;
    for (let j = 0; j < optionsInVariant.length; j++) {
      let optionInVariant = optionsInVariant[j];
      if (
        optionSelected.name === optionInVariant.name &&
        optionSelected.value === optionInVariant.value
      ) {
        found = true;
        break;
      }
    }
    if (!found) return false;
  }
  return true;
}
